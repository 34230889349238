import styled from "@emotion/styled";

import headerImg from "./baldcat-header.png";

export const DISPLAY_SIZES = {
  MOBILE_S: "320px",
  MOBILE_M: "375px",
  MOBILE_L: "425px",
  TABLET: "768px",
  LAPTOP: "1024px",
  LAPTOP_L: "1440px",
  DESKTOP: "1920px",
  DESKTOP_L: "2560px",
  DESKTOP_XL: "3840px",
};

export const Container = styled.div`
  background-color: #51787c;
  color: #fff;
  min-height: 100vh;
  width: 100vw;
  font-family: "Nanum Pen Script";
  font-weight: 700;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: unset;
    width: 80vw;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-direction: column;
  }
`;

export const BaldCatImg = styled.img`
  height: 40vh;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: unset;
    width: 80vw;
  }
`;

export const Header = styled.div`
  padding: 32px 80px;
  font-size: 64px;
  box-sizing: border-box;

  background-image: url(${headerImg});
  background-size: cover;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
    font-size: 32px;
  }
`;

export const Content = styled.div`
  padding: 32px 80px;
  box-sizing: border-box;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    padding: 16px;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 80px;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SocialLogoContainer = styled.div`
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 80px;
    width: 80px;
  }
`;

export const SocialLogo = styled.img<{ dexscreener?: boolean }>`
  height: 120px;
  width: 120px;
  transition: 0.2s height, 0.2s width, 0.2s opacity;
  border-radius: 50%;
  cursor: pointer;

  ${({ dexscreener }) =>
    dexscreener
      ? `
    background: #000;
    box-sizing: border-box;
    padding: 16px;
  `
      : ""}

  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));

  @media (max-width: ${DISPLAY_SIZES.TABLET}) {
    height: 75px;
    width: 75px;
  }
`;

export const Btn = styled.button`
  padding: 16px 48px;
  background-color: white;
  font-family: "Nanum Pen Script";
  font-weight: 700;
  border-radius: 8px;
  font-size: 32px;
  margin-bottom: 16px;
  cursor: pointer;

  transition: 0.2s transform;

  :hover {
    transform: scale(1.05);
  }

  :active {
    transform: scale(0.95);
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  height: calc(100vh - 138px);
  display: flex;
  justify-content: center;
`;

export const Video = styled.video`
  object-fit: contain;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
`;

export const SoundBtnContainer = styled.div`
  position: absolute;
  bottom: 24px;
`;
