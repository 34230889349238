import React, { useCallback, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BaldCatImg,
  Btn,
  Container,
  Content,
  Header,
  InnerContainer,
  SocialLogo,
  SocialLogoContainer,
  Socials,
  SoundBtnContainer,
  Video,
  VideoContainer,
} from "./App.styles";

// import baldCat from "./baldcat.jpeg";
import baldCat from "./baldcat-gif.gif";

import twitter from "./twitter.png";
import tg from "./tg.png";
import dexscreener from "./dexscreener.svg";

const BUY_NOW_LINK =
  "https://www.sushi.com/swap?fromChainId=8453&fromCurrency=NATIVE&toChainId=8453&toCurrency=0x1D963D57C65d4A02B2D379C5104b39378a95CcdA&amount=0.69";
const TWITTER_LINK = "https://twitter.com/lmeowonbase";
const TELEGRAM_LINK = "https://t.me/LmeowBASE";
const DEXSCREENER_LINK =
  "https://dexscreener.com/base/0x1D963D57C65d4A02B2D379C5104b39378a95CcdA";

function App() {
  const onBuyNow = () => window.open(BUY_NOW_LINK, "_blank");
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = useCallback(() => {
    setIsMuted(!isMuted);
  }, [isMuted]);

  return (
    <Container>
      <Header>BALDCAT</Header>
      <InnerContainer>
        <div style={{ height: "100%" }}>
          <Content>
            <div>
              <Btn onClick={onBuyNow}>BUY NOW</Btn>
            </div>

            <BaldCatImg src={baldCat} />

            <Socials>
              <SocialLogoContainer
                onClick={() => window.open(TWITTER_LINK, "_blank")}
              >
                <SocialLogo src={twitter} />
              </SocialLogoContainer>
              <SocialLogoContainer
                onClick={() => window.open(TELEGRAM_LINK, "_blank")}
              >
                <SocialLogo src={tg} />
              </SocialLogoContainer>
              <SocialLogoContainer
                onClick={() => window.open(DEXSCREENER_LINK, "_blank")}
              >
                <SocialLogo dexscreener src={dexscreener} />
              </SocialLogoContainer>
            </Socials>
          </Content>
        </div>

        <VideoContainer>
          <Video loop autoPlay playsInline muted={isMuted} poster={baldCat}>
            <source src={"/baldcatvideo.mp4"} type="video/mp4" />
          </Video>

          <SoundBtnContainer>
            <Btn onClick={toggleMute}>SOUND {isMuted ? "OFF" : "ON"}</Btn>
          </SoundBtnContainer>
        </VideoContainer>
      </InnerContainer>
    </Container>
  );
}

export default App;
